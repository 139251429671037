
$(document).ready(function() {


    //check if platform is iphone
    function getRealOptionsNb() {
        if(navigator.userAgent.match(/iPhone/i)) {
            return 1;
        } else {
            return 0;
        }
    }


    function refreshAds() {
        googletag.pubads().refresh();
    }

    var cooldown = $('#refresh-cooldown').text();
    var refresh = $.throttle ( cooldown * 1000 ,function() { refreshAds() });
    setTimeout(function() {
        $('.search-generate-button').click(
            refresh
        );
        $('.generate-pdf-button').click(
            refresh
        );
    } , cooldown * 1000);

    //search for swimmers

    $('.search-generate-button').on('click',function() {
        $('.generated-table').empty();
        $('.load-list-error').hide();
        var nameValidation = 0;
        var lengthValidation = 0;
        var errorMessage = $('.error-message');
        var firstName = $('.first-name').val();
        var lastName = $('.last-name').val();
        $('.update-error').hide();
        errorMessage.hide();
        $('.error-message span').text('');
        var searchError = $(this).data('searcherror');
        //validate name

        $('.name-input').each(function() {
            if($.trim($(this).val()).length) {
                nameValidation = 1;
            }
            if($.trim($(this).val()).length > 2) {
                lengthValidation = 1;
            }
        });

        // validated
        if(nameValidation == 1) {
            if(lengthValidation == 1) {
                $.ajax({
                    beforeSend:  function() {
                        $('body').addClass("loading");
                    },
                    url:'/search/swimmer',
                    method:'POST',
                    data: {first_name:firstName, last_name:lastName},
                    success: function(data) {
                        $('#tabletimetypes').show();
                        $('.show-button').hide();
                        $('.hide-button').show();
                        if(typeof data == 'string') {
                            $('body').removeClass("loading");
                            $('.error-message span').text('').text(data);
                            errorMessage.show();
                        } else {
                            var body = $('#search-body');
                            body.empty();
                            if ($.isEmptyObject(data)) {
                                $('.search-table').hide();
                                $('.error-message').show();
                                $('.error-message span').text('').text(searchError);
                            }
                            var hidden = $('.swimmers-row tr td');
                            if(Object.keys(data[0]['data']).length > 0) {
                                $('.list_id_hidden').val('');
                                $.each(data[0]['data'] ,function(id,value) {
                                    hidden.find('.add-compare').show();
                                    hidden.find('.search_id').val(id);
                                    hidden.find('.gender').text('').text(value.gender);
                                    hidden.find('.gender-displayed').text('').text(value.gender.toUpperCase());
                                    hidden.find('.last').text('').text(value.last_name);
                                    hidden.find('.first').text('').text(value.first_name);
                                    hidden.find('.age').text('').text(value.age);
                                    hidden.find('.club').text('').text(value.club);
                                    if(!$.isEmptyObject(data[1])) {
                                        if(data[1]['data'][id]) {
                                            hidden.find('.add-compare').hide();
                                        } else {
                                            hidden.find('.add-compare').show();
                                        }
                                    }
                                    if(!$.isEmptyObject(data[2])) {
                                        if(data[2][id]) {
                                            hidden.find('.add-list').hide();
                                        } else {
                                            hidden.find('.add-list').show();
                                        }
                                    }
                                    var html = $('.swimmers-row').html();
                                    body.append(html);
                                    $('.search-table').show();

                                });
                                if(data[0]['more_available'] == true && Object.keys(data[0]['data']).length == 25) {
                                    body.append('<tr><td colspan="6">More swimmers available. Please narrow your search.</td></tr>')
                                }
                            } else {
                                $('.error-message span').text('').text('No swimmer found. Please verify the searching information and try again!')
                                errorMessage.show();
                            }
                            $('body').removeClass("loading");
                        }
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        if(xhr.status == 401) {
                            window.location.href = '/auth/login'
                        }
                    }
                });
            } else if(lengthValidation == 0) {
                errorMessage.show();
                var msg = errorMessage.data('errorlength');
                $('.error-message span').text('').text(msg);
            }



        } else if (nameValidation == 0) {
            errorMessage.show();
            var msg = errorMessage.data('errormsg');
            $('.error-message span').text('').text(msg);
        }
    });





//generate pdf or comparison
    $('.generate-pdf-button').on('click',function(e) {
        e.preventDefault();
        $('.compare-event-error').hide();
        $('.update-error').hide();
        var checkboxEventError = $('.checkbox-event-error');
        checkboxEventError.hide();
        var checkboxError = $('.checkbox-error');
        checkboxError.hide();
        var courseError = $('.course-error');
        courseError.hide();
        var eventError = $('.event-error');
        eventError.hide();
        var compare = [];
        $('.swimmer-options option:selected').each(function() {
            compare.push($(this).val())
        });
        var platform = getRealOptionsNb();
        if(platform == 1) {
            var inArray = $.inArray('default', compare);
            if(inArray == 1) {
                compare.splice(inArray, 1);
                if(compare.length < 2) {
                    $('.search_swimmer_id').val(compare[0]);
                }
            }
        };
        var SearchGenerate = $('#search-generate');

        var swimmerId = $('.search_swimmer_id').val();
        if(compare.length < 2 && !swimmerId) {
            var selectedMeets = $('.meet-name:checked');
            var selectedGenders = $('.gender-checkbox:checked');
            var selectedCourses = $('.course-checkbox:checked');
            var selectedEvents = $('.meets-options option:selected');
            $('.btn-check').val('0');
            if(selectedMeets.length == 0) {
                checkboxEventError.show();
                return;
            }
            if(selectedGenders.length == 0) {
                checkboxError.show();
                return;
            }
            if(selectedCourses.length == 0) {
                courseError.show();
                return;
            }
            if(selectedEvents.length == 0) {
                eventError.show();
                return;
            }
            var urlMeet = SearchGenerate.attr('data-meet');
            $.ajax({
                beforeSend: function() {
                    $('body').addClass("loading");
                },
                type: "POST",
                url:urlMeet,
                data: SearchGenerate.serialize(),
                success : function(table) {
                    $('.generated-table-container').doubleScroll().show();
                    $('.generated-table').empty().append(table);
                    $('body').removeClass("loading");
                }
            })
        } else if(compare.length < 2) {
            var swimValidation = validate();
            $('.btn-check').val('0');
            if(swimValidation == 0) {
                var url = SearchGenerate.attr('data-pdf');
                $.ajax({
                    beforeSend:  function() {
                        $('body').addClass("loading");
                    },
                    type: "POST",
                    url: url,
                    data: SearchGenerate.serialize(),
                    success: function(table) {
                        $('.generated-table-container').doubleScroll().show();
                        $('.generated-table').empty().append(table);
                        $('body').removeClass("loading");
                    }
                });
            }
        } else {
            var swimError = $('.swim-error');
            var swimErrorTable = $('.swim-error-table');
            var eventError = $('.event-error');
            swimError.hide();
            swimErrorTable.hide();
            $('.checkbox-error').hide();
            $('.course-error').hide();
            eventError.hide();
            var selectedEvents = $('.meets-options').val();
            if(!selectedEvents) {
                eventError.show();
                return;
            }
            if(!$('.lc-checkbox').is(':checked') && !$('.sc-checkbox').is(':checked')){
                $('.course-error').show();
                return;
            }
            var meets = [];
            $('.meet-name:checked').each(function() {
                meets.push($(this).val());
            });
            var gender = [];
            $('.gender-checkbox:checked').each(function() {
                gender.push($(this).val());
            });
            if(gender.length == 0) {
                $('.checkbox-error').show();
                return;
            }
            $('.btn-check').val('0');
            if(meets.length < 2) {
                var events = [];
                $('.meets-options option:selected').each(function() {
                    events.push($(this).val());
                });
                $.ajax({
                    beforeSend: function () {
                        $('body').addClass("loading");
                    },
                    type: "POST",
                    url: '/generate/compare',
                    data: SearchGenerate.serialize(),
                    success: function (table) {
                        $('.generated-table-container').doubleScroll().show();
                        $('.generated-table').empty().append(table);
                        $('body').removeClass("loading");
                    }
                });
            } else {
                $('.compare-event-error').show();
            }

        }
    });

//download a generate table or comparison

    $('.download-pdf-button').on('click',function(e) {
        $('.update-error').hide();
        e.preventDefault();
        var compare = [];
        var checkboxEventError = $('.checkbox-event-error');
        checkboxEventError.hide();
        var checkboxError = $('.checkbox-error');
        checkboxError.hide();
        var courseError = $('.course-error');
        courseError.hide();
        var eventError = $('.event-error');
        eventError.hide();
        $('.swimmer-options option:selected').each(function() {
            compare.push($(this).val())
        });
        var platform = getRealOptionsNb();
        if(platform == 1) {
            var inArray = $.inArray('default', compare);
            if(inArray == 1) {
                compare.splice(inArray, 1);
                if(compare.length < 2) {
                    $('.search_swimmer_id').val(compare[0]);
                }
            }
        };
        var SearchGenerate = $('#search-generate');

        var swimmerId = $('.search_swimmer_id').val();
        if(compare.length < 2 && !swimmerId) {
            var selectedMeets = $('.meet-name:checked');
            var selectedGenders = $('.gender-checkbox:checked');
            var selectedCourses = $('.course-checkbox:checked');
            var selectedEvents = $('.meets-options option:selected');
            $('.btn-check').val('1');
            if(selectedMeets.length == 0) {
                checkboxEventError.show();
                return;
            }
            if(selectedGenders.length == 0) {
                checkboxError.show();
                return;
            }
            if(selectedCourses.length == 0) {
                courseError.show();
                return;
            }
            if(selectedEvents.length == 0) {
                eventError.show();
                return;
            }
            var actionPDF = SearchGenerate.attr('data-meet');
            SearchGenerate.attr('action', actionPDF);
            $('.btn-check').val('1');
            $('#search-generate').submit();
        } else if(compare.length < 2) {
            var swimValidation = validate();
            var actionPDF = SearchGenerate.attr('data-pdf');
            SearchGenerate.attr('action', actionPDF);
            $('.btn-check').val('1');
            if(swimValidation == 0) {
                $('#search-generate').submit();
            }
        } else {
            var swimError = $('.swim-error');
            var swimErrorTable = $('.swim-error-table');
            var eventError = $('.event-error');
            swimError.hide();
            swimErrorTable.hide();
            $('.checkbox-error').hide();
            $('.course-error').hide();
            eventError.hide();
            var meets = [];
            var events = $('.meets-options').val();
            if(!events) {
                eventError.show();
                return;
            }
            if(!$('.lc-checkbox').is(':checked') && !$('.sc-checkbox').is(':checked')){
                $('.course-error').show();
                return;
            }
            $('.meet-name:checked').each(function() {
                meets.push($(this).val());
            });
            if(meets.length < 2) {
                var actionCompare = $('#search-generate').attr('data-compare');
                $('#search-generate').attr('action', actionCompare);
                $('.btn-check').val('1');
                $('#search-generate').submit();
            } else {
                $('.compare-event-error').show();
            }
        }
    });

    $('.download-image-button').on('click',function(e) {
        $('.update-error').hide();
        e.preventDefault();
        var compare = [];
        $('.swimmer-options option:selected').each(function() {
            compare.push($(this).val())
        });
        var platform = getRealOptionsNb();
        if(platform == 1) {
            var inArray = $.inArray('default', compare);
            if(inArray == 1) {
                compare.splice(inArray, 1);
                if(compare.length < 2) {
                    $('.search_swimmer_id').val(compare[0]);
                }
            }
        };
        var checkboxEventError = $('.checkbox-event-error');
        checkboxEventError.hide();
        var checkboxError = $('.checkbox-error');
        checkboxError.hide();
        var courseError = $('.course-error');
        courseError.hide();
        var eventError = $('.event-error');
        eventError.hide();
        var SearchGenerate = $('#search-generate');
        var swimmerId = $('.search_swimmer_id').val();
        if(compare.length < 2 && !swimmerId) {

            var selectedMeets = $('.meet-name:checked');
            var selectedGenders = $('.gender-checkbox:checked');
            var selectedCourses = $('.course-checkbox:checked');
            var selectedEvents = $('.meets-options option:selected');
            $('.btn-check').val('2');
            if(selectedMeets.length == 0) {
                checkboxEventError.show();
                return;
            }
            if(selectedGenders.length == 0) {
                checkboxError.show();
                return;
            }
            if(selectedCourses.length == 0) {
                courseError.show();
                return;
            }
            if(selectedEvents.length == 0) {
                eventError.show();
                return;
            }
            var actionPDF = SearchGenerate.attr('data-meet');
            SearchGenerate.attr('action', actionPDF);
            $('.btn-check').val('2');
            $('#search-generate').submit();
        } else if(compare.length < 2) {
            var swimValidation = validate();
            var actionPDF = $('#search-generate').attr('data-pdf');
            $('#search-generate').attr('action', actionPDF);
            $('.btn-check').val('2');
            if(swimValidation == 0) {
                $('#search-generate').submit();
            }
        } else {
            var swimError = $('.swim-error');
            var swimErrorTable = $('.swim-error-table');
            var eventError = $('.event-error');
            var checkboxEventError = $('.checkbox-event-error');
            swimError.hide();
            swimErrorTable.hide();
            $('.checkbox-error').hide();
            $('.course-error').hide();
            eventError.hide();
            checkboxEventError.hide();
            var meets = [];
            var events = $('.meets-options').val();
            if(!events) {
                eventError.show();
                return;
            }
            if(!$('.lc-checkbox').is(':checked') && !$('.sc-checkbox').is(':checked')){
                $('.course-error').show();
                return;
            }
            $('.meet-name:checked').each(function() {
                meets.push($(this).val());
            });
            if(meets.length < 2) {
                var actionCompare = $('#search-generate').attr('data-compare');
                $('#search-generate').attr('action', actionCompare);
                $('.btn-check').val('2');
                $('#search-generate').submit();
            } else {
                $('.compare-event-error').show();
            }
        }
    });

    //generate chart link button
    $('.generate-link-button').on('click' , function(e) {
        e.preventDefault();
        var queriesData = $('#search-generate').serialize();
        var selectEventError = $('.swim-error-table');
        var checkboxEventError = $('.checkbox-event-error');
        selectEventError.hide();
        checkboxEventError.hide();
        var checkboxError = $('.checkbox-error');
        checkboxError.hide();
        var courseError = $('.course-error');
        courseError.hide();
        var eventError = $('.event-error');
        eventError.hide();
        var selectedSwimmers = $('.search_swimmer_id').val();
        var selectedMeets = $('.meet-name:checked');
        var compareSwimmers = $('.swimmer-options').val();
        var selectedGenders = $('.gender-checkbox:checked');
        var selectedCourses = $('.course-checkbox:checked');
        var selectedEvents = $('.meets-options option:selected');

        // console.log(compareSwimmers);
        if(!selectedSwimmers && !compareSwimmers && selectedMeets.length == 0) {
            if(!selectedSwimmers && !compareSwimmers) {
                selectEventError.show();
            } else {
                checkboxEventError.show();
            }
            return;
        }

        if(selectedGenders.length == 0) {
            checkboxError.show();
            return;
        }
        if(selectedCourses.length == 0) {
            courseError.show();
            return;
        }
        if(selectedEvents.length == 0) {
            eventError.show();
            return;
        }
        var swimValidation = validate();
        if(swimValidation == 0) {
            $.ajax({
                type:"POST",
                url: "/generate/link",
                data: queriesData,
                success : function(data) {
                    $('.link-modal').show();
                    if(data['is_error']) {
                        $('.link-content').hide();
                        $('.list-content').append("<span class='error'>" + data['err_msg'] + '</span>');
                    }
                    else {
                        $('.link-content').text('').text(data['url']);
                        $('.link-content').attr('href' , data['url']);
                    } 

                    $('body').addClass('modal-loading');                    
                }
            });
        }
    })
});
