

$(document).ready(function() {


    // //make default swimmer id null
    // $('.search_swimmer_id').val(null);

    // $('.swimmer-options option:selected').each(function() {
    //     $(this).prop('selected',false);
    // });
    
    //unckeck all checkboxes
    // $('input:checkbox').each(function() {
    //     // $(this).prop("checked", false);
    //     // $('.lc-checkbox').prop("checked", true);
    //     // $('.sc-checkbox').prop("checked", true);
    // });

    //trigger generating the pdf after loading the page

    // var setTimer = $("#set-timeout").text();
    // setTimeout(function() {
    //     $('.generate-pdf-button').trigger('click');
    // },setTimer * 1000);

    //show clear regions button if regions are selected
    $('.region-checkbox').each(function() {
        var isQuery = $('.is_query').text();
        var $this = $(this);
        if(isQuery != 1) {
            if($this.is(':checked')) {
                $('.region-button').show();
                var regionId = $this.attr('data-regionid');
                $('.region-' + regionId).show();
            }
        }
    });


    $('.gender-checkbox').each(function() {
        var genderCheckbox = $(this);
        $('.meet-name').each(function () {
            var meetName = $(this);
            var maledisabled = meetName.closest('label').attr('data-maledisabled');
            var femaledisabled = meetName.closest('label').attr('data-femaledisabled');
            if (!(maledisabled == 1 && femaledisabled == 1)) {
                if (genderCheckbox.hasClass('male-checkbox')) {
                    if (maledisabled == 1 && (!$('.female-checkbox').is(':checked'))) {
                        meetName.prop('checked', false).closest('.form-group').hide().find('.meet-children').hide();
                    } else {
                        meetName.closest('.form-group').show();
                    }
                    if ($('.female-checkbox').is(':checked') && femaledisabled == 0) {
                        meetName.closest('.form-group').show();
                    }
                    if (!$('.male-checkbox').is(':checked') && femaledisabled == 1) {
                        meetName.prop('checked', false).closest('.form-group').hide().find('.meet-children').hide();
                    }
                }
                if (genderCheckbox.hasClass('female-checkbox')) {
                    if (femaledisabled == 1 && (!$('.male-checkbox').is(':checked'))) {
                        meetName.prop('checked', false).closest('.form-group').hide().find('.meet-children').hide();
                    } else {
                        meetName.closest('.form-group').show();
                    }
                    if ($('.male-checkbox').is(':checked') && maledisabled == 0) {
                        meetName.closest('.form-group').show();
                    }
                    if (!$('.female-checkbox').is(':checked') && maledisabled == 1) {
                        meetName.prop('checked', false).closest('.form-group').hide().find('.meet-children').hide();
                    }
                }
            }
        });
    });


    if (window.location.hash && window.location.hash == '#_=_') {
        if (window.history && history.pushState) {
            window.history.pushState("", document.title, window.location.pathname);
        } else {
            // Prevent scrolling by storing the page's current scroll offset
            var scroll = {
                top: document.body.scrollTop,
                left: document.body.scrollLeft
            };
            window.location.hash = '';
            // Restore the scroll offset, should be flicker free
            document.body.scrollTop = scroll.top;
            document.body.scrollLeft = scroll.left;
        }
    }

    var isQuery = $('.is_query').text();

    if($.trim(isQuery) == '1') {
        setTimeout(function() {
            $('.hide-button').trigger('click');
        },10);
    } else if($.trim(isQuery) == '') {
        //show groups which have selected meets
        $('.meet-name').each(function () {
            var $this = $(this);
            if ($this.is(':checked')) {
                var group = $this.closest('.group-container').find('.group-checkbox');
                var groupValue = $this.closest('.group-container').find('.group-checkbox').text();
                if (groupValue == '[+]') {
                    group.text('').text('[-]');
                    $this.closest('.group-container').find('.group-meets').fadeToggle(200);
                    $this.closest('.group-container').find('.clear-group-button').show(0);
                }
            }
        });
    }
    setTimeout(function (){
        $('.list-select option').attr('selected',false);
    }, 10);



    if(navigator.userAgent.match(/iPhone/i)) {
        $('.swimmer-options').each(function(){
            var select = $(this).on({
                "focusout": function(){
                    var values = select.val() || [];
                    setTimeout(function(){
                        select.val(values.length ? values : ['']).change();
                    }, 1000);
                }
            });
            var firstOption = '<option value="" disabled="disabled"';
            // firstOption += (select.val() || []).length > 0 ? '' : ' selected="selected"';
            firstOption += '>&laquo; Select ' + (select.attr('title') || 'Swimmers') + ' &raquo;';
            firstOption += '</option>';
            select.prepend(firstOption);
        });
        $('.meets-options').each(function(){
            var select = $(this).on({
                "focusout": function(){
                    var values = select.val() || [];
                    setTimeout(function(){
                        select.val(values.length ? values : ['']).change();
                    }, 1000);
                }
            });
            var firstOption = '<option value="" disabled="disabled"';
            // firstOption += (select.val() || []).length > 0 ? '' : ' selected="selected"';
            firstOption += '>&laquo; Select ' + (select.attr('title') || 'Event') + ' &raquo;';
            firstOption += '</option>';
            select.prepend(firstOption);
        });
        $('.list-select').each(function(){
            var select = $(this).on({
                "focusout": function(){
                    var values = select.val() || [];
                    setTimeout(function(){
                        select.val(values.length ? values : ['']).change();
                    }, 1000);
                }
            });
            var firstOption = '<option value="" disabled="disabled"';
            // firstOption += (select.val() || []).length > 0 ? '' : ' selected="selected"';
            firstOption += '>&laquo; Select ' + (select.attr('title') || 'List') + ' &raquo;';
            firstOption += '</option>';
            select.prepend(firstOption);
        });
    }

    $('.swimmer-options option:not(:disabled)').prop('selected' , true);
    
    var swimmers = $('.swimmer-options option:selected');
    if(swimmers.length < 2) {
        $('.order-type').hide();
        $('.compare-selects').hide();
        $('.compare-relay').find('select').val('default');
        $('.compare-event').find('select').val('default');
        $('.compare-options').val('default');
        if(swimmers.length == 1) {
            $('.search_swimmer_id').val(swimmers.val());
        }
    } else {
        $('.search_swimmer_id').val('');
    }

    $('.generated-table').doubleScroll();



    // $('.order-type').find('select').val('default');
    // $('.compare-relay').find('select').val('default');
});
