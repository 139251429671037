
//validate search

function searchValidation() {
    var validation = 1;
    var errorMessage = $('.error-message');
    errorMessage.hide();
    var lastName = $('.last-name').val();
    var firstName = $('.first-name').val();
    var year = $('#year-input').val();

    if($.trim(lastName).length == 0) {
        validation = 0;
        errorMessage.show();
        var msg = errorMessage.data('errormsg');
        $('.error-message span').text('').text(msg);
    }
    if($.trim(firstName).length > 0 && $.trim(firstName).length < 3) {
        validation = 0;
        errorMessage.show();
        var msg = errorMessage.data('errorlength');
        $('.error-message span').text('').text(msg);
    }

    var thisYear = $('').val();
    var date = new Date();
    var currentYear = date.getFullYear();

    if(year.length > 0) {
        if ( year.length < 4 || year.length > 4 || year > currentYear || year < 1900 || isNaN(year)) {
            validation = 0;
            errorMessage.show();
            var msg = errorMessage.data('erroryear');
            $('.error-message span').text('').text(msg);
        }
    }

    if (!($('input[name^=agreement]:checked').length > 0)) {
        validation = 0;
        errorMessage.show();
        var msg = errorMessage.data('erroragreement');
        $('.error-message span').text('').text(msg);
    }


    return validation;
}


//validate table generation

function validate() {
    var swimError = $('.swim-error');
    var eventError = $('.event-error');
    var checkboxEventError = $('.checkbox-event-error');
    swimError.hide();
    $('.checkbox-error').hide();
    $('.course-error').hide();
    eventError.hide();
    checkboxEventError.hide();
    var swimValidation = 0;
    var meets = $('input[name^="meets"]:checked');
    var events = $('.meets-options').val();
    if(!events) {
        eventError.show();
        swimValidation = 1;
    }
    if(!$('.lc-checkbox').is(':checked') && !$('.sc-checkbox').is(':checked')){
        $('.course-error').show();
        swimValidation = 1;
    }
    var swimmerId = $('.search_swimmer_id');
    var searchedSwimmers = $('.swimmer-radio');
    if(searchedSwimmers.length == 0) {
        $('.swim-error-table').show();
        swimValidation = 1;
    } else if (swimmerId.val() == '') {
        // swimError.show();
        // swimValidation = 1;
    }
    if (meets.length == 0) {
        //checkboxEventError.show();
        //swimValidation = 1;
    } else {
        meets.each(function() {
            var male = $('input[name^="male"]:checked');
            var female = $('input[name^="female"]:checked');
            if(male.length == 0 && female.length == 0) {
                $('.checkbox-error').show();
                swimValidation = 1;
            }
        });
    }
    return swimValidation;
}


//validate comparing swimmers and move gender checkboxes accordingly

function swimmerCompareValidation() {
    var genderMale = 0;
    var genderFemale = 0;
    var swimmerSelected = $('.swimmer-options option:selected');
    swimmerSelected.each(function() {
        if($(this).attr('data-gender') == 'm') {
            genderMale = 1;
        }
        if($(this).attr('data-gender') == 'f') {
            genderFemale = 1;
        }
    });
    var maleCheckbox = $('.male-checkbox');
    var femaleCheckbox = $('.female-checkbox');
    if(genderMale == 1 && genderFemale == 1) {
        if(!maleCheckbox.is(':checked')) {
            maleCheckbox.trigger('click').attr('checked','checked');
        }
        if(!femaleCheckbox.is(':checked')) {
            femaleCheckbox.trigger('click').attr('checked','checked');
        }
    }
    if(genderMale == 1 && genderFemale == 0) {
        if(!maleCheckbox.is(':checked')) {
            maleCheckbox.trigger('click').attr('checked','checked');
        }
        if(femaleCheckbox.is(':checked')) {
            femaleCheckbox.trigger('click').attr('checked',false);
        }
    }
    if(genderMale == 0 && genderFemale == 1) {
        if(maleCheckbox.is(':checked')) {
            maleCheckbox.trigger('click').attr('checked',false);
        }
        if(!femaleCheckbox.is(':checked')) {
            femaleCheckbox.trigger('click').attr('checked','checked');
        }
    }
    if((genderMale == 0 && genderFemale == 0) || swimmerSelected == null) {
        if(!maleCheckbox.is(':checked')) {
            maleCheckbox.trigger('click').attr('checked',true);
        }
        if(!femaleCheckbox.is(':checked')) {
            femaleCheckbox.trigger('click').attr('checked',true);
        }
    }
    
}