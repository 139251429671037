
$(document).ready(function() {
//select all events
    $('.select-all-button').on('click',function() {
        var lapsToggle = $('.laps-checkbox').is(':checked');
        if(lapsToggle == true) {
            $('.meets-options option:enabled').prop('selected', true);
        } else {
            $('.meets-options option:enabled').each(function() {
                var $this = $(this);
                var split = $this.text().split(" ");
                if(split[split.length - 1] != 'Laps') {
                    $this.prop('selected',true);
                } else {
                    $this.prop('selected',false);
                }
            });

        }
        var events = $('.meets-options option:selected').clone();
        $('.compare-event-select').empty().append(events);
    });

    //select laps
    $('.laps-checkbox').on('click',function() {
        $('.select-all-button').trigger('click');
    });

//deselect all events
    $('.deselect-all-button').on('click',function() {
        $('.meets-options option').prop('selected', false);
        var events = $('.meets-options option:selected').clone();
        $('.compare-event-select').empty().append(events);
    });

//select all swimmers
    $('.select-all-swimmers').on('click',function(e) {
        e.preventDefault();
        $('.swimmer-options option:enabled').prop('selected', true);
        var options = $('.swimmer-options option:selected');
        $('.fina-checkbox').prop('checked',false);
        if(options.length > 1) {
            $('.order-type').show();
            $('.compare-options').val('default');
        }
        if(options.length == 1) {
            $('.search_swimmer_id').val('').val(options.val());
            $('.order-type').hide();
        } else {
            $('.search_swimmer_id').val('');
        }
        swimmerCompareValidation();
    });

//deselect all swimmers
    $('.deselect-all-swimmers').on('click',function(e) {
        e.preventDefault();
        $('.list_id_hidden').val('');
        $('.swimmer-options option').prop('selected', false);
        swimmerCompareValidation();
        $('.order-type').hide();
        $('.compare-selects').hide();
        $('.compare-relay').find('select').val('default');
        $('.compare-event').find('select').val('default');
        $('.compare-options').val('default');
        var swimmerRadio = $('.swimmer-radio:checked');
        $('.fina-checkbox').prop('checked',false);
        if(swimmerRadio) {
            swimmerRadio.click();
            $('.search_swimmer_id').val(swimmerRadio.val());
        } else {
            $('.search_swimmer_id').val('');
        }
        $('.gender-checkbox').prop('checked' ,false).trigger('click').prop('checked' ,true);
    });

//remove swimmers from comparison multiselect

    $('.remove-swimmer').on('click',function(e) {
        e.preventDefault();
        $('.list_id_hidden').val('');
        var idArray = [];
        var options = $('.swimmer-options option:selected');
        options.each(function() {
            idArray.push($(this).val());
        });
        if(idArray.length > 0) {
            $('.list-select option').prop('selected' , false);
        }
        $.ajax({
            type: "POST",
            url: '/compare/swimmers/remove',
            data: {id:idArray},
            success: function(data) {
                if(data == 1) {
                    options.remove();
                    $.each(idArray,function(key , data) {
                        $('.swimmer-radio[value="'+ data +'"]').closest('tr').find('.add-compare').show();
                    })
                }
                swimmerCompareValidation();
                var remaining = $('.swimmer-options option');
                if(remaining.length == 0) {
                    $('.select-all-swimmers').attr('disabled',true);
                    $('.deselect-all-swimmers').attr('disabled',true);
                    $('.remove-swimmer').attr('disabled',true);
                }
                var swimmerRadio = $('.swimmer-radio:checked');
                if(swimmerRadio) {
                    swimmerRadio.click();
                    $('.search_swimmer_id').val(swimmerRadio.val());
                } else {
                    $('.search_swimmer_id').val('');
                }
            }

        });
        $('.order-type').hide();
        $('.compare-selects').hide();
        $('.gender-checkbox').prop('checked' ,false).trigger('click').prop('checked' ,true);
        $('.compare-relay').find('select').val('default');
        $('.compare-event').find('select').val('default');
    });

//prevent lc and sc checkboxes be deselected at the same time

    // $('.lc-checkbox').on('click' ,function(e) {
    //     var validate = 0;
    //     if($('.sc-checkbox').is(':checked')) {
    //         $('.course-error').hide();
    //         validate = 1;
    //     }
    //     if (validate == 0) {
    //         e.preventDefault();
    //         $('.course-error').show();
    //     }
    // });
    //
    // $('.sc-checkbox').on('click' ,function(e) {
    //     var validate = 0;
    //     if($('.lc-checkbox').is(':checked')) {
    //         $('.course-error').hide();
    //         validate = 1;
    //     }
    //     if (validate == 0) {
    //         e.preventDefault();
    //         $('.course-error').show();
    //     }
    // });

//check gender when selecting for compare

    $('.swimmer-options').on('change' ,function() {
        var swimmerSelected = $('.swimmer-options option:selected');
        $('.list_id_hidden').val('');
        if(swimmerSelected.length == 1) {
            $('.search_swimmer_id').val('').val(swimmerSelected.val());
            $('.order-type').hide();
            $('.compare-selects').hide();
            $('.compare-relay').find('select').val('default');
            $('.compare-event').find('select').val('default');
            $('.compare-options').val('default');
        }
        if(swimmerSelected.length > 1) {
            $('.order-type').show();
            $('.fina-checkbox').prop('checked',false);
            $('.search_swimmer_id').val('');
        }
        swimmerCompareValidation();
    });

//region "Dropdown"

    $('.region-checkbox').on('click' ,function() {
        var $this = $(this);
        var id = $this.data('regionid');
        if($('.region-' + id).is(':hidden') && !$this.is(':checked')) {
            $(this).prop('checked' , true);
            $('.region-' + id).toggle(200);
        } else {
            $('.region-' + id).toggle(200);
        }
        var checked = 0;
        $('.region-' + id).find('.meet-name').each(function() {
            if($(this).is(':checked')) {
                checked = 1;
            }
        });
        if(checked == 1 && !$this.is(':checked')) {
            $this.closest('label').css('background' , '#DDDDDD');
        } else {
            $this.closest('label').css('background' , '#fff');
        }
        var selectedRegions = $('.region-checkbox:checked');
        var selectedMeets = $('.meet-name:checked');
        if (selectedRegions.length == 0) {
            if(selectedMeets.length == 0) {
                $('.region-button').hide();
            }
        } else {
            $('.region-button').show();
            //$('.compare-options option[value="default"]').prop('selected',true);
        }
    });

    //fina chekcbox

    $('.fina-checkbox').on('click',function() {
        if($(this).is(':checked')) {
            $('.compare-options option[value="3"]').attr('disabled',true);
            $('.compare-options option[value="default"]').prop('selected',true);
            $('.compare-selects').hide();
            $('.compare-relay option[value="default"]').prop('selected',true);
        } else {
            $('.compare-options option[value="3"]').attr('disabled',false);
        }
    });

//group "Dropdown"

    $('.group-name-checkbox').on('click' ,function(e) {
        var $this = $(this).find('.group-checkbox');
        var text = $this.text();
        e.preventDefault();
        $this.closest('.group-container').find('.group-meets').fadeToggle(200);
        if(text == '[-]') {
            $this.text('').text('[+]');
        } else if (text == '[+]') {
            $this.text('').text('[-]');
        }
    });

//meet "Dropdown"

    $('.meet-name').on('click',function() {
        var meetName = $(this);
        meetName.closest('.row').find('.meet-children').toggle(200);
        $('.compare-options').val('default').change();
        $('.compare-relay').find('select').val('default');
        meetName.closest('.row').find('.var').attr('checked',false).click().attr('checked',true);
        meetName.closest('.group-container').find('.clear-group-button').show();
        var selectedMeets = meetName.closest('.group-meets').find('.meet-name:checked');
        var fullSelectedMeets = $('.meet-name:checked');
        if(selectedMeets.length == 0) {
            meetName.closest('.group-container').find('.clear-group-button').hide();
            if(fullSelectedMeets.length == 0) {
                $('.fina-row-toggled').show();
                $('.compare-options option[value="1"]').attr('disabled',false);
                $('.compare-options option[value="3"]').attr('disabled',false);
            }
        } else {
            $('.fina-row-toggled').hide();
            $('.fina-checkbox').attr('checked',false);
            $('.compare-options option[value="1"]').attr('disabled',true);
            $('.compare-options option[value="3"]').attr('disabled',true);
        }
    });

//remove or place a meet depending on disabled status

    $('.gender-checkbox').on('click' ,function() {
        var genderCheckbox = $(this);
        $('.meet-name').each(function() {
            var meetName = $(this);
            var maledisabled = meetName.closest('label').attr('data-maledisabled');
            var femaledisabled = meetName.closest('label').attr('data-femaledisabled');
            if(!(maledisabled == 1 && femaledisabled == 1)) {
                if(genderCheckbox.hasClass('male-checkbox')) {
                    if(maledisabled == 1 && (!$('.female-checkbox').is(':checked'))) {
                        meetName.prop('checked',false).closest('.form-group').hide().find('.meet-children').hide();
                    } else {
                        meetName.closest('.form-group').show();
                    }
                    if($('.female-checkbox').is(':checked') && femaledisabled == 0) {
                        meetName.closest('.form-group').show();
                    }
                    if(!$('.male-checkbox').is(':checked') && femaledisabled == 1) {
                        meetName.prop('checked',false).closest('.form-group').hide().find('.meet-children').hide();
                    }
                }
                if(genderCheckbox.hasClass('female-checkbox')) {
                    if(femaledisabled == 1 && (!$('.male-checkbox').is(':checked'))) {
                        meetName.prop('checked',false).closest('.form-group').hide().find('.meet-children').hide();
                    } else {
                        meetName.closest('.form-group').show();
                    }
                    if($('.male-checkbox').is(':checked') && maledisabled == 0) {
                        meetName.closest('.form-group').show();
                    }
                    if(!$('.female-checkbox').is(':checked') && maledisabled == 1) {
                        meetName.prop('checked',false).closest('.form-group').hide().find('.meet-children').hide();
                    }
                }
            }
        });
        var fullSelectedMeets = $('.meet-name:checked');
        if(fullSelectedMeets.length == 0) {
            $('.fina-row-toggled').show();
            $('.fina-checkbox').prop('checked', false);
            $('.compare-options option[value="1"]').attr('disabled',false);
            $('.compare-options option[value="3"]').attr('disabled',false);
        }
    });



    // select radio buttons group (same name)
    var radioButtons = $("input[name='search_id']");
    // save initial ckecked states
    var radioStates = {};
    $.each(radioButtons, function(index, rd) {
        radioStates[rd.value] = $(rd).is(':checked');
    });
//click anywhere in the same row to select the radio button

    $('#search-body').on('click','.swimmer-table-row',function(e) {
        var $this = $(this);
        if(e.target.nodeName != 'INPUT') {
            if (($this.find('.search_id').is(':checked'))) {
                $this.find('.search_id').prop('checked', false);
                $('.swimmer-options option:selected').each(function () {
                    $(this).prop('selected', false);
                });
                $('.search_swimmer_id').val('');
                $('.gender-checkbox').attr('checked', false);
                $('.male-checkbox').click();
                $('.female-checkbox').click();
            } else {
                $this.find('.search_id').prop('checked', true);
                $('.swimmer-options option:selected').each(function () {
                    $(this).prop('selected', false);
                });

                $('.search_swimmer_id').val($this.find('.search_id').val());
                var gender = $this.find('.gender').text();
                $('.gender-checkbox').attr('checked', false);
                if (gender == 'm') {
                    $('.male-checkbox').click().prop('checked', true);
                }
                if (gender == 'f') {
                    $('.female-checkbox').click().prop('checked', true);
                }

                $('.order-type').hide();
                $('.compare-selects').hide();
                $('.compare-relay').find('select').val('default');
                $('.compare-event').find('select').val('default');
            }
            var val = $this.find('.search_id').val();
            // update other buttons checked state
            $.each(radioButtons, function(index, rd) {
                if(rd.value !== val) {
                    radioStates[rd.value] = false;
                }
            });
        }
    });


    // handle click event
    radioButtons.click(function() {
        // check/unchek radio button
        var $this = $(this);
        var val = $this.val();
        $this.prop('checked', (radioStates[val] = !radioStates[val]));

        //change gender checkboxes and selects

        if(!radioStates[val] == false) {
            $('.swimmer-options option:selected').each(function () {
                $(this).prop('selected', false);
            });

            $('.search_swimmer_id').val($this.closest('tr').find('.search_id').val());
            var gender = $this.closest('tr').find('.gender').text();
            $('.gender-checkbox').attr('checked', false);
            if (gender == 'm') {
                $('.male-checkbox').click().prop('checked', true);
            }
            if (gender == 'f') {
                $('.female-checkbox').click().prop('checked', true);
            }

            $('.order-type').hide();
            $('.compare-selects').hide();
            $('.compare-relay').find('select').val('default');
            $('.compare-event').find('select').val('default');
        } else {
            $('.swimmer-options option:selected').each(function () {
                $(this).prop('selected', false);
            });
            $('.search_swimmer_id').val('');
            $('.gender-checkbox').attr('checked', false);
            $('.male-checkbox').click();
            $('.female-checkbox').click();
        }

        // update other buttons checked state
        $.each(radioButtons, function(index, rd) {
            if(rd.value !== val) {
                radioStates[rd.value] = false;
            }
        });
    });

//add swimmer to session compare and display them in swimmer multiselect

    $('#search-body').on('click','.add-compare',function(e) {
        e.preventDefault();
        var compare = $(this);
        var id = $(this).closest('tr').find('.swimmer-radio').val();
        $.ajax({
            type: "GET",
            url: '/compare/swimmers/add/' + id,
            success: function(data) {
                if(data != 0) {
                    $.each(data,function(key,value) {
                        $('.swimmer-options').append('<option value="'+key+'" data-gender="'+value[1]+'">'+value[0]+'</option>')
                    })
                }
                $('.select-all-swimmers').trigger('click').attr('disabled',false);
                $('.deselect-all-swimmers').attr('disabled',false);
                $('.remove-swimmer').attr('disabled',false);
                compare.hide();
            }
        });
    });

//clear region selection

    $('.clear-region-button').on('click',function() {
        $('.checkbox-group').find('.group-checkbox').each(function() {
            if($(this).text() == '[-]') {
                $(this).click();
            }
        });
        $('.checkbox-group').find('input:checkbox').each(function() {
            if($(this).is(':checked')) {
                $(this).click().prop('checked',false);
            }
        });
        $('.region-list').find('label').css('background' , '#fff');
        $('.region-button').hide();
        $('.clear-group-button').hide();
        if(!$('.fina-checkbox').is(':checked')) {
            $('.compare-options option[value="3"]').attr('disabled',false);
        }

    });

//clear group selection

    $('.clear-group-button').on('click',function() {
        $(this).closest('.row').find('.group-checkbox').trigger('click');
        $(this).closest('.select-row').find('input:checkbox').each(function() {
            if($(this).is(':checked')) {
                $(this).click().prop('checked',false);
            }
        });
        $($(this)).hide();
        var checkedMeets = $('.meet-name:checked');
        if(checkedMeets.length == 0) {
            $('.compare-options option[value="3"]').attr('disabled',false);
        } else {
            $('.fina-row-toggled').hide();
        }
    });

    //order by type

    $('.compare-options').on('change' , function() {
        var $thisValue = $(this).find('option:selected').val();
        var eventSelect = $('.meets-options option:selected:first').val();
        if($thisValue == 2 || $thisValue == 3) {
            if($thisValue == 2) {
                $('.compare-event').show();
                if(eventSelect) {
                    $('.compare-event').find('select').val(eventSelect);
                } else {
                    $('.compare-event').find('select').val('default');
                }
                $('.compare-relay').hide();
                $('.compare-relay').find('select').val('default');
            } else if ($thisValue == 3) {
                $('.compare-relay').show();
                $('.compare-event').hide();
                $('.compare-event').find('select').val('default');
            }
            $('.compare-selects').show("fast");
        } else {
            $('.compare-selects').hide();
        }
    });

    //std disabled

    $('.type-checkbox').on('click' , function() {
        var $this = $(this);
        var stdDisabled = $this.closest('.select-row').find('.name-checkbox').attr('data-stddisabled');
        if(stdDisabled == 1) {
            var checked = 0;
            $this.closest('.select-row').find('.type-checkbox').each(function() {
                if($(this).is(':checked')) {
                    checked = 1;
                }
            });
            if (checked == 0) {
                $this.closest('.select-row').find('.meet-name').click().prop('checked',false);
            }
        }

    });

    //on order type change , change relay value

    $('.order-type select').on('change' , function() {
        if($(this).val() != 3) {
            $('.compare-relay').find('select').val('default');
        } else {
            var events = [];
            $('.meets-options option:selected').each(function() {
                events.push($(this).text());
            });
            var done = 0;
            $.each(events,function(key , event) {
                $('.compare-relay select option').each(function() {
                    if(event == $(this).text()) {
                        var a = $('.compare-relay select option:contains(' + event + ')').attr("selected",true);
                        done = 1;
                        return false;
                    }
                });
                if(done == 1) {
                    return false;
                }
            });
        }
    });

    $('.list-select').on('change' , function() {
        // var $this = $(this);
        // var selected = $this.find('option:selected');
        // if(selected.length > 0) {
        //     $('.load-list').attr('disabled',false);
        // } else {
        //     $('.load-list').attr('disabled',true);
        // }
        var list = $(this).closest('.list-select-container').find('.list-select').val();
        $('.load-list-error').hide();
        $.ajax({
            method: "get",
            url : '/lists/show/swimmers/' + list,
            success: function(data) {
                var body = $('#search-body');
                $('.compare-selects').hide();
                $('.compare-relay option[value="default"]').prop('selected' ,true);
                $('.compare-event option[value="default"]').prop('selected' ,true);
                body.empty();
                var hidden = $('.swimmers-row tr td');
                var compare = $('.swimmer-options');
                if(data != 'fail') {
                    compare.empty();
                    if(data.length > 0) {
                        $.each(data , function(id,value) {
                            if(list && list.length > 1) {
                                $('.list_id_hidden').val('');
                            } else {
                                $('.list_id_hidden').val(list);
                            }
                            compare.append('<option data-gender="' + value.gender + '" value="' + value.swimmer_id+ '"> ' + value.last_name + ' , ' + value.first_name  + '</option>');
                            hidden.find('.search_id').val(value.swimmer_id);
                            hidden.find('.gender').text('').text(value.gender);
                            hidden.find('.gender-displayed').text('').text(value.gender.toUpperCase());
                            hidden.find('.first').text('').text(value.first_name);
                            hidden.find('.last').text('').text(value.last_name);
                            hidden.find('.age').text('').text(value.age);
                            hidden.find('.club').text('').text(value.club);
                            hidden.find('.add-compare').hide();
                            hidden.find('.add-list').hide();
                            var html = $('.swimmers-row').html();
                            body.append(html);
                            $('.search-table').show();
                            $('.hide-button').trigger('click');
                            $('.remove-swimmer').attr('disabled',false);
                            $('.deselect-all-swimmers').attr('disabled',false);
                            $('.select-all-swimmers').attr('disabled',false);
                        });
                    } else {
                        $('.select-all-swimmers').attr('disabled',true);
                        $('.deselect-all-swimmers').attr('disabled',true);
                        $('.remove-swimmer').attr('disabled',true);
                    }
                    $('.select-all-swimmers').trigger('click');
                } else {
                    $('.load-list-error').show();
                }
            }
        })
    });

    $('.load-list').on('click' ,function(e) {
        e.preventDefault();

    });

    $('.hide-button').on('click' , function(e) {
        e.preventDefault();
        $('#tabletimetypes').hide();
        $('.hide-table-input').val(1);
        $(this).hide();
        $('.show-button').show();
    });

    $('.show-button').on('click' , function(e) {
        e.preventDefault();
        $('#tabletimetypes').show();
        $('.hide-table-input').val(0);
        $(this).hide();
        $('.hide-button').show();
    });


    $('.back-button').on('click',function() {
        $('.text-modal').hide();
        $('.link-modal').hide();
        $('.tooltip-modal').hide();
        $('body').removeClass('modal-loading');
    });
    
    $('.back-button-expire').on('click' , function() {
        $('.expire-modal').hide();
        var text = $('#expire-message-search').text();
        $('.error-message span').text('').text(text);
        $('.error-message').show();
        $('body').removeClass('modal-loading');
    });

    //order by relay select all events
    //
    // $('.order-relay select').on('change' , function() {
    //
    // });



    $('.meets-options').on('change' , function() {
        var $this = $(this);
        var events = $this.find('option:selected').clone();
        $('.compare-event-select').empty().append(events);
    });


    //query triggers

    $('.save-query-button').on('click' , function(e) {
        e.preventDefault();
        var $this = $(this);
        var queryForm = $('.query-form');
        var name = $('.query-form').find('input').val();
        if(queryForm.is(':visible')) {
            if($.trim(name) != '') {
                var searchFormData = $('#search-form').serialize();
                var queriesData = $('#search-generate').serialize();
                $.ajax({
                    type:"POST",
                    url: "/query/save",
                    data: queriesData + searchFormData,
                    success : function(data) {
                        if(data == 2) {
                            $('.query-name-unique').show();
                        } else if(data != 0) {
                            $('.query-select').append('<option value="'+ data.id +'">'+ data.name +'</option>');
                            $('.load-query').show();
                            queryForm.hide();
                        }
                    }
                });
            } else {
                $('.query-name-error').show();
            }
        } else {
            queryForm.show();
        }
    });

    // $('.query-select').on('change' , function() {
    //     var $this = $(this);
    //     var selectedQueries = $('.query-select option:selected');
    //     if(selectedQueries.length > 0) {
    //         $('.load-query').attr('disabled' , false);
    //     } else {
    //         $('.load-query').attr('disabled' , true);
    //     }
    // });
    
    //load the query (will reload the page)
    $('.query-select').on('change' , function() {
        var $this = $(this);
        var id = $this.val();
        if(id != 'default') {
            $.ajax({
                method: "GET",
                url: "/query/load/" + id,
                success: function(data) {
                    if(data == 1) {
                        $(location).attr('href', '/')
                    }
                }

            })
        }
    });


    //hide meets and disable regions

    $('.hide-meets-button').on('click' , function(e) {
        e.preventDefault();
        $(this).hide();
        $('.show-meets-button').show();
        var checked = 0;
        $('.region-list').each(function() {
            $(this).find('.region-checkbox').attr('disabled',true);
            if($(this).find('.region-checkbox').is(':checked')) {
                checked = 1;
            }
        });
        if(checked == 1) {
            $('.region-button').hide();
        }
        $('.hide-checkboxes-input').val(1);
        $('.checkbox-groups-container').hide();
    });

    //show meets and enable regions

    $('.show-meets-button').on('click' , function(e) {
        e.preventDefault();
        $(this).hide();
        $('.hide-meets-button').show();
        var checked = 0;
        $('.region-list').each(function() {
            $(this).find('.region-checkbox').attr('disabled',false);
            if($(this).find('.region-checkbox').is(':checked')) {
                checked = 1;
            }
        });
        if(checked == 1) {
            $('.region-button').show();
        }
        $('.hide-checkboxes-input').val(0);
        $('.checkbox-groups-container').show();
    });

    var generateTrigger = null;
    var query = $('#loaded_query').text();
    console.log(query);
    if(query) {
        console.log(query);
        var setTimer = $("#set-timeout").text();
        setTimer = setTimer * 1000;
        clearTimeout(generateTrigger);
        generateTrigger = setTimeout(function() {
            $('.generate-pdf-button').trigger('click');
        },setTimer);
    }

    $('.meet-name').on('change', function() {
        if($('.meet-name').is(':checked')) {
            var setTimer = $("#set-timeout").text();
            setTimer = setTimer * 1000;
            clearTimeout(generateTrigger);
            generateTrigger = setTimeout(function() {
                $('.generate-pdf-button').trigger('click');
            },setTimer);
        }
    });

    $('.swimmer-radio').on('change' , function() {
        if($('.meet-name').is(':checked')) {
            var setTimer = $("#set-timeout").text();
            setTimer = setTimer * 1000;
            clearTimeout(generateTrigger);
            generateTrigger = setTimeout(function() {
                $('.generate-pdf-button').trigger('click');
            },setTimer);
        }
    });

    $('.list-select').on('change' , function() {
        if($('.meet-name').is(':checked')) {
            var setTimer = $("#set-timeout").text();
            setTimer = setTimer * 1000;
            clearTimeout(generateTrigger);
            generateTrigger = setTimeout(function() {
                $('.generate-pdf-button').trigger('click');
            },setTimer);
        }
    });

    $('.swimmer-options').on('click' , function() {
        if($('.meet-name').is(':checked')) {
            var setTimer = $("#set-timeout").text();
            setTimer = setTimer * 1000;
            clearTimeout(generateTrigger);
            generateTrigger = setTimeout(function() {
                $('.generate-pdf-button').trigger('click');
            },setTimer);
        }
    });

    $('.bottom-checkboxes').on('click' , function() {
        if($('.meet-name').is(':checked')) {
            var setTimer = $("#set-timeout").text();
            setTimer = setTimer * 1000;
            clearTimeout(generateTrigger);
            generateTrigger = setTimeout(function() {
                $('.generate-pdf-button').trigger('click');
            },setTimer);
        }
    });
    
    $('.middle-checkboxes').on('click' , function() {
        if($('.meet-name').is(':checked')) {
            var setTimer = $("#set-timeout").text();
            setTimer = setTimer * 1000;
            clearTimeout(generateTrigger);
            generateTrigger = setTimeout(function() {
                $('.generate-pdf-button').trigger('click');
            },setTimer);
        }
    });
    // $('input').on('click' , function() {
    // });

    // $('select').on('change' , function() {
    //     var setTimer = $("#set-timeout").text();
    //     setTimer = setTimer * 1000;
    //     clearTimeout(generateTrigger);
    //     generateTrigger = setTimeout(function() {
    //         $('.generate-pdf-button').trigger('click');
    //     },setTimer);
    // });

    $('.generate-pdf-button').on('click' , function() {
        clearTimeout(generateTrigger);
    });

    function displayTooltips(key) {
        $('.modal-tooltip').hide();
        $('.tooltip-modal').show();
        $('body').addClass('modal-loading');
        $('#' + key).show();
    }

    //tooltips
    $('.tooltip-container').on('click' , function() {
        var $this = $(this);
        var key = $this.data('id');
        displayTooltips(key);
    });

    $('.search-list').on('click' , '.tooltip-container' , function() {
        var $this = $(this);
        var key = $this.data('id');
        displayTooltips(key);
    });

    $('#search-body').on({
        mouseenter: function(e) {
            $('.table-tooltip').css({visibility:'visible' , position:"absolute", top:e.pageY});
        },
        mouseleave: function() {
            $('.table-tooltip').css('visibility', 'hidden')
        }
    } , '.table-tooltip-container');

    $('.submit-swim-link').on('click' , function() {
        $('.loading-modal').show();
    });


    // $('#search-body').on('mouseenter' , '.table-tooltip-container' ,
    //
    // );
    //
    // $('#search-body').on('mouseleave' , '.table-tooltip-container' ,
    //     function(){$('.table-tooltip').css('visibility', 'hidden')}
    // );

    // new Tether({
    //     element: '.table-tooltip',
    //     target: '.table-tooltip-container',
    //     attachment: 'top right',
    //     targetAttachment: 'top left'
    // });
});