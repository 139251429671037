
$(document).ready(function() {

    //change time in modal depending on the event
    
    $('.event-search').on('change',function() {
        var event = $(this).find(':selected').val();
        var type = $('.type-search').find(':selected').val();
        var id = $('.search_swimmer_id').val();
        $.ajax({
            type:"post",
            url: "/swimmer/time",
            data: {id :id , event:event, type:type},
            success: function(data) {
                if(data == 0) {
                    $('.hours-input').val('00');
                    $('.minutes-input').val('00');
                    $('.seconds-input').val('00');
                    $('.ms-input').val('00');
                } else {
                    $('.hours-input').val(data[1][0]);
                    $('.minutes-input').val(data[1][1]);
                    $('.seconds-input').val(data[1][2]);
                    $('.ms-input').val(data[0]);
                }
            }
        })
    });
    
    //change time in modal depending on the type
    
    $('.type-search').on('change',function() {
        var type = $(this).find(':selected').val();
        var event = $('.event-search').find(':selected').val();
        var id = $('.search_swimmer_id').val();
        $.ajax({
            type:"post",
            url: "/swimmer/time",
            data: {id :id , event:event, type:type},
            success: function(data) {
                if(data == 0) {
                    $('.hours-input').val('00');
                    $('.minutes-input').val('00');
                    $('.seconds-input').val('00');
                    $('.ms-input').val('00');
                } else {
                    $('.hours-input').val(data[1][0]);
                    $('.minutes-input').val(data[1][1]);
                    $('.seconds-input').val(data[1][2]);
                    $('.ms-input').val(data[0]);
                }
            }
        })
    });
    
    //update session with time for event
    
    $('.update-event').on('click',function(e) {
        e.preventDefault();
        var $this = $(this);
        var updateError = $('.update-error');
        updateError.hide();
        var id = $('.search_swimmer_id').val();
        if(id) {
            $('.session-changed').html('');
            $.ajax({
                type:"post",
                url:"/check/session",
                data:{data:id},
                success: function(sessionArray) {
                    if(sessionArray != 0) {
                        $.each(sessionArray , function(key,  data) {
                            var html = $('<p class="col-md-12"><span class="col-md-3 updated-event-name" data-event="'+key +'">'+ key +' : </span></p>');
                            $('.session-changed').append(html);
                            $.each(data , function(data_key, value ) {
                                if(data_key == '25m') {
                                    html.append('&nbsp&nbsp<span class="col-md-2" data-type="sc"> [SC]  '+ value +'</span><button type="button" class="btn btn-xs btn-danger remove-time-session col-md-1"><span>Remove</span></button>')
                                } else if (data_key == '50m') {
                                    html.append('&nbsp&nbsp<span class="col-md-2" data-type="lc"> [LC]  '+ value +'</span><button type="button" class="btn btn-xs btn-danger remove-time-session col-md-1"><span>Remove</span></button>')
                                }
                            })
                        })
                    }
                }
            });
            $('.update-modal').find('.swimmer_id').val(id);
            $('body').addClass('updating').addClass('modal-loading');
            $('.update-modal').show();
            $('.event-search').change();
        } else {
            updateError.show();
        }
    });

    //remove session time for event
    
    $('.session-changed').on('click','.remove-time-session',function() {
        var id   = $('.swimmer_id').val();
        var button = $(this);
        var elementName = button.closest('p').first();
        var elementType = button.prev();
        var name = button.closest('p').find('.updated-event-name').attr('data-event');
        var type = button.prev().attr('data-type');
        $.ajax({
            type:"POST",
            url:"/delete/event/session",
            data:{name:name , type:type , id:id},
            success: function(data) {
                if(data == 1) {
                    elementType.hide();
                    button.hide();
                }
                if(data == 2) {
                    elementName.hide();
                    elementType.hide();
                    button.hide();
                }
            }
        })
    });
    
    //up button for time inputs
    $('.up').on('click',function(e) {
        e.preventDefault();
        var input = $(this).closest('.update-text').prev().find('input');
        var number = input.val();
        if (input.attr('name') == 'ms') {
            if(number && number < 99) {
                number = parseInt(number) + 1;
            }
        } else {
            if(number && number < 59) {
                number = parseInt(number) + 1;
            }
        }
        if (number <10 ) {
            input.val('0'+ number);
        } else {
            input.val(number);
        }
    });
    
    //down button for time inputs
    $('.down').on('click',function(e) {
        e.preventDefault();
        var input = $(this).closest('.update-text').prev().find('input');
        var number = input.val();
        if(number && number > 0) {
            number = parseInt(number) - 1;
        }
        if (number === 0) {
            number = '0' + number;
        }
        if (number < 10 && number > 0 ) {
            input.val('0'+ number);
        } else {
            input.val(number);
        }
    });
    
    //submit event to session
    
    $('.submit-event-button').on('click',function(e) {
        e.preventDefault();
        var url = $('.update-event-form').attr('action');
        $.ajax({
            type: "POST",
            url: url,
            data: $('.update-event-form').serialize(),
            success: function(session) {
                $('.session-changed').html('');
                $('.error-modal').text('');
                if(session != 0) {
                    $.each(session , function(key,  data) {
                        var html = $('<p class="col-md-12"><span class="col-md-3 updated-event-name" data-event="'+key +'">'+ key +' : </span></p>');
                        $('.session-changed').append(html);
                        $.each(data , function(data_key, value ) {
                            if(data_key == '25m') {
                                html.append('&nbsp&nbsp<span class="col-md-2" data-type="sc"> [SC]  '+ value +'</span><button type="button" class="btn btn-xs btn-danger remove-time-session col-md-1"><span>Remove</span></button>')
                            } else if (data_key == '50m') {
                                html.append('&nbsp&nbsp<span class="col-md-2" data-type="lc"> [LC]  '+ value +'</span><button type="button" class="btn btn-xs btn-danger remove-time-session col-md-1"><span>Remove</span></button>')
                            }
                        })
                    })
                }
            },
            error: function(xhr, ajaxOptions, thrownError) {
                $.each(xhr.responseJSON , function(key,error) {
                    $('.error-modal').text('').append('<span>' + error + '</span>');
                });
            }
        });
    });
    
    //back button
    
    $('.back-button').on('click',function(){
        $('body').removeClass('updating');
        $('.update-modal').hide();
        $('.list-modal').hide();
    });


    //list modal

    $('#search-body').on('click' , '.add-list' ,function(e) {
        e.preventDefault();
        var $this = $(this);
        // var userId = $this.attr('data-id');
        // var html = $('#list-container').html();
        // var listContent = $('.list-content');
        var tableRow = $this.closest('.swimmer-table-row');
        var firstName = tableRow.find('.first').text();
        var lastName = tableRow.find('.last').text();
        var gender = tableRow.find('.gender').text();
        var club = tableRow.find('.club').text();
        var age = tableRow.find('.age').text();
        var swimmerId = tableRow.find('.search_id').val();
        var swimData = [firstName,lastName,club,gender,age,swimmerId];
        $('.error-row').hide();
        $.ajax({
            method:"POST",
            url: "/lists/add/swimmer",
            data: {data: swimData},
            success : function(data) {
                if(data == 1) {
                    $this.hide();
                } else if(data == 0) {
                    $('.text-modal').show();
                    $('body').addClass('modal-loading');
                }
            }
        });
        // $('body').addClass('updating');
        // $('.list-modal').show();
    });

    $('.list-modal').on('click','.add-swimmer-to-list' , function(e) {
        e.preventDefault();
        var $this = $(this);
        var lists = $this.closest('.lists');
        var listId = lists.find('.list-radio').val();
        var listName = lists.find('.list-name').text();
        var firstName = lists.find('.list-first-name').val();
        var lastName = lists.find('.list-last-name').val();
        var club = lists.find('.list-club').val();
        var gender = lists.find('.list-gender').val();
        var age = lists.find('.list-age').val();
        var swimmerId = lists.find('.list-id').val();
        var swimData = [firstName,lastName,club,gender,age,swimmerId];
        $.ajax({
            method:"POST",
            url: "/lists/add/swimmer",
            data: {listId : listId , data: swimData},
            success: function(listCount) {
                $this.hide();
                $this.closest('.lists').find('.remove-swimmer-from-list').show();
                var listSelect = $('.list-select').find('option[value="' + listId + '"]');
                listSelect.text('').text(listName + ' / ' + listCount + ' swimmers');
            }
        })
    });

    $('.list-modal').on('click' , '.remove-swimmer-from-list' , function(e) {
        e.preventDefault();
        var $this = $(this);
        var lists = $this.closest('.lists');
        var listName = lists.find('.list-name').text();
        var listId = lists.find('.list-radio').val();
        var swimmerId = lists.find('.list-id').val();
        $.ajax({
            method:"POST",
            url: "/lists/remove/swimmer",
            data: {listId : listId , swimmerId: swimmerId},
            success: function(listCount) {
                $this.hide();
                $this.closest('.lists').find('.add-swimmer-to-list').show();
                var listSelect = $('.list-select').find('option[value="' + listId + '"]');
                listSelect.text('').text(listName + ' / ' + listCount + ' swimmers');
            }
        })

    })

});
